@font-face {
  font-family: FontRegular;
  font-style: normal;
  font-display: swap;
  src: url(../public/fonts/Inter-Medium.ttf)
}

@font-face {
  font-family: FontItalic;
  font-style: italic;
  font-display: swap;
  src: url(../public/fonts/Inter-MediumItalic.otf)
}

@font-face {
  font-family: FontBold;
  font-style: normal;
  font-display: swap;
  src: url(../public/fonts/Inter-ExtraBold.ttf)
}

@font-face {
  font-family: FontBoldItalic;
  font-style: italic;
  font-display: swap;
  src: url(../public/fonts/Inter-ExtraBoldItalic.otf)
}

* {
  font-family: FontRegular;
}

html,body
{
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden; 
}

header {
  width: 100%;
  height: auto;
  display: flex;

  justify-content: center;
  align-items: center;

  color: #fff;

  background-color: #4e229d;

  font-size: 0.75em;

  padding: 0.25em 0;

  img {
    width: 30px;
    margin-right: 0.5em;
  }

  button.header-support {
    margin-left: 0.5em;

    font-family: FontBold;

    background-color: #00daa4;
    border: none;
    color: #fff;
    padding: 0.5em 1em;
    border-radius: 2em;
  }
}

.hero-poster {
  height: 600px;
  width: 100%;

  background: url("../public/images/hero-image.svg") no-repeat center center, linear-gradient(120deg,#09d8a2 -7.33%,#0ec9a1 -1.37%,#1f9aa1 19.56%,#2d74a0 40.48%,#3856a0 61.16%,#3f41a0 81.45%,#4434a0 101.11%,#4630a0 119.5%);
  background-size: cover;
}

.goal-raised {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // span.gr {
  //   font-size: 1em;
  //   padding: 1em 3em;
  //   border-radius: 2em;

  //   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  //   &.raised {
  //     background-color: #00daa4;
  //     color: #fff;
  //   }

  //   &.goal {
  //     background: #fff;
  //     color: #716f82;
  //   }
  // }
  .progress {
    width: 80%;
    height: 50px;
  }
  .progress-wrap {
    background: #fff;
    margin: 20px 0;
    overflow: hidden;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 2em;
    .progress-bar {
      background: linear-gradient(120deg,#09d8a2 -7.33%,#0ec9a1 -1.37%,#1f9aa1 19.56%,#2d74a0 40.48%,#3856a0 61.16%,#3f41a0 81.45%,#4434a0 101.11%,#4630a0 119.5%);
      left: 0;
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
  }

  h4 {
    color: #716f82;
    margin: 0.5em;
  }
}

.video-offer {
  width: 100%;

  display: flex;
  flex-direction: row;

  .video {
    width: 50%;
    padding-right: 2em;

    iframe {
      float: right;
    }
  }
  .offer {
    width: 50%;
    padding-left: 2em;
    color: #716f82;

    font-size: 1.2em;

    p {
      max-width: 80%;
    }

    button {
      font-family: FontBold;
  
      background-color: #00daa4;
      border: none;
      color: #fff;
      padding: 1em 3em;
      font-size: 0.8em;
      border-radius: 2em;

      margin-right: 1em;
      &:first-of-type {
        background-color: #ffffff;
        color: #716f82;
        box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
      }
    }
  }
}

.center-container {
  color: #716f82;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .content-container {
    width: 60%;

    span.about-title {
      background: #4e229d;
    }

    span.faq-title {
      background: #fe53ab;
    }

    p.about-paragraph:first-of-type {
      margin-top: 2em;
    }

    .faq-element {
      margin: 2em 0;
      padding: 0.5em 2em;
      border-radius: 3em;
      font-size: 14px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px 4px;

      .faq-answer {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: opacity 200ms linear, max-height 200ms linear;
        will-change: opacity, max-height;
      }

      &:hover {
        cursor: pointer;
      }

      .faq-head {
        display: flex;
        flex-direction: row;

        h3 {
          display: flex;
          font-weight: normal;
          width: 100%;
          font-size: 16px;

          span {
            margin-left: auto;
            order: 2;
            img {
              width: 16px;
            }
          }
        }

        h3:hover, p:hover {
          cursor: default;
        }
      }

      &.active {
        background: 
          linear-gradient(#fff 0 0) padding-box,
          linear-gradient(120deg,#09d8a2 -7.33%,#0ec9a1 -1.37%,#1f9aa1 19.56%,#2d74a0 40.48%,#3856a0 61.16%,#3f41a0 81.45%,#4434a0 101.11%,#4630a0 119.5%) border-box;
        border: 3px solid transparent;
        border-radius: 1em;
        h3 {
          font-weight: 600;
        }
        .faq-answer {
          opacity: 1;
          max-height: fit-content;
          transition: all 400ms linear;
          will-change: opacity, max-height;
        }
      }
    }
  }
}

footer {
  height: auto;
  width: 100%;
  padding: 1em 0;

  background: linear-gradient(120deg,#09d8a2 -7.33%,#0ec9a1 -1.37%,#1f9aa1 19.56%,#2d74a0 40.48%,#3856a0 61.16%,#3f41a0 81.45%,#4434a0 101.11%,#4630a0 119.5%);

  color: #fff;
  font-weight: normal;

  display: flex;
  align-items: center;
  justify-content: center;

  .content-container {
    width: 98%;
    
    display: flex;
    flex-direction: row;
    align-items: center;

    div.footer-text, ul {
      width: 45%;
    }

    div.footer-text {
      display: flex;
      justify-content: left;
      align-items: center;
      img {
        width: 20px;
        margin-left: 0.5em;
      }
    }

    a.social-link {
      width: 8%;
      text-align: center;

      img {
        width: 36px;
      }
    }

    ul {
      list-style: none;
      text-align: right;
      li {
        display: inline;
        padding: 0 1em;
        a {
          color: #fff;
        }
      }
    }
  }
}

/* General Styles */
span.bold {
  font-family: FontBold;
}

span.full-line {
  display: block;
}

span.big-text {
  font-size: 3em;
}

div.space {
  margin: 2em 0;
}

a {
  color: #00daa4;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.container-shadow {
  padding: 3em 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

span.title {
  color: #fff;
  border-radius: 2em;
  padding: 0.75em 1.5em;
}

button:hover {
    cursor: pointer;
}

/* Media Queries */
@media screen and (min-width: 1440px) {
    .hero-poster {
        background-size: contain;
    }
}
@media screen and (max-width: 768px) {
  header {
    padding: 1em 0;
  }

  .video-offer {
    flex-direction: column;
    .video {
      width: 100%;
      display: flex;
      justify-content: center;
      iframe {
        float: none;
      }
    }
    .offer {
      padding-left: 0;
      width: 80%;
      margin: 1em auto;

      p {
        margin: 0 auto;
        max-width: 100%;
      }

      button {
        margin-bottom: 2em;
      }
    }
  }

  .center-container .content-container {
      width: 80%;
  }

  footer {
    .content-container {
      flex-direction: column;

      div.footer-text, ul, a.social-link {
        width: 100%;
      }

      div.footer-text {
        justify-content: center;
        p {
          text-align: center;
        }

        img {
          display: none;
        }
      }

      ul {
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 0;
      }
    }
  }
}
